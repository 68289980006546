body {
  margin: 0;
  padding: 0 0 150px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Set the background image */
  /*background-image: url('img/background.webp');*/
  background-image: url('img/background15.jpeg');

  /* Ensure the background image is positioned at the bottom */
  background-position: bottom;

  /* Make sure the background does not repeat */
  /*background-repeat: no-repeat;*/
  background-repeat: repeat-x;

  /* Optionally, cover the entire page or use a specific size */
  /*background-size: cover; !* Adjust as needed: contain, auto, etc. *!*/
  background-size: auto; /* Adjust as needed: contain, auto, etc. */

  /* Set a height for the page to ensure the image is displayed properly */
  min-height: 100vh;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/* ENDLESS ROTATE */
.rotate{
  animation: rotate 4s linear infinite;
}
@keyframes rotate{
  to{ transform: rotate(360deg); }
}

.santaSection {
  width: 80%;
  padding: 20px;
}

@media (max-width: 768px) { /* Mobile devices */
  .santaSection {
    width: 100%;
  }
}

@media (min-width: 2560px) { /* Ultra-wide monitors */
  .santaSection {
    width: 40%;
  }
}